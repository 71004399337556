@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ag-react-container {
  height: 100%;
}

.react-select-menu-cmap {
  @media screen and (min-width: 1300px) {
    top: -8px !important;
    left: 320px;
  }
}

// Get font awesome css pseudo to work
.fa-icon-cmap::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

// Ag-grid theme styles
$ag-icons-path: '~../../node_modules/ag-grid-community/src/styles/ag-theme-material/icons/';
$primary-color: #9dd162;
$background-color: #184562;
$border-color: black;
$header-background-color: #184562;
$header-foreground-color: #9dd162;
$foreground-color: rgb(255, 246, 236);
$secondary-foreground-color: #9dd162;
$chip-background-color: lighten(#184562, 10%);
$hover-color: darken(#9dd162, 35%);
$panel-background-color: darken(#184562, 5%);
$row-stub-background-color: #22a3b9;

// $menu-option-active-color: #22A3B9;
@import '../../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';

// teal #22A3B9;

// React-select scrollbar styles
#custom-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

// Data Grid Style

#DatasetAGGrid .ag-theme-material {
  background-color: #184562;
}

.ag-theme-material .ag-popup-editor,
#DatasetAGGrid .ag-theme-material .ag-popup-editor {
  background-color: #184562;
}

.ag-theme-material .ag-cell-inline-editing,
#DatasetAGGrid .ag-theme-material .ag-cell-inline-editing {
  background-color: #184562;
  height: auto;
}

//
#DatasetAGGrid .ag-theme-material .ag-side-bar {
  background-color: #184562;
  border-left: 2px solid black;
}

#DatasetAGGrid .ag-theme-material .ag-tool-panel-wrapper {
  border-left: 2px solid black;
}

@media screen and (min-width: 480px) {
    #DatasetAGGrid .ag-theme-material .ag-tool-panel-wrapper {
        width: 300px;
    }
}
@media screen and (min-width: 800px) {
    #DatasetAGGrid .ag-theme-material .ag-tool-panel-wrapper {
        width: 500px;
    }
}
@media screen and (min-width: 1200px) {
    #DatasetAGGrid .ag-theme-material .ag-tool-panel-wrapper {
        width: 900px;
    }
}

#DatasetAGGrid .ag-theme-material .ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}
.ag-theme-material .ag-side-bar .ag-side-buttons .ag-selected button {
  background-color: #184562;
}

#DatasetAGGrid h4 {
    padding: 5px;
    margin: 18px auto;
    border-bottom: 2px solid #9dd162;
}

// force select to look like part of the theme
#DatasetAGGrid .ag-theme-material .ag-tab-body .ag-filter-select {
   color: white;
   background-color: #184562;
   border: 1px solid #9dd162;
   border-radius: 3px;
   padding: 5px;
}

// make the input label more visible
#DatasetAGGrid .ag-theme-material .ag-filter input[type="text"]::placeholder, .ag-theme-material .ag-filter input[type="date"]::placeholder {
    color: rgba(255, 255, 255, 0.38);
}

// make the input value more visible
#DatasetAGGrid .ag-theme-material .ag-floating-filter-body input {
    padding-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}
// add a bolder box shadow to make the filter menue easier to see
#DatasetAGGrid .ag-theme-material .ag-menu {
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.25), 0 1px 5px 0 rgba(0, 0, 0, 0.25);
}

#DatasetAGGrid .ag-row-focus {
    // border: 2px solid #9dd162;
    background: rgba(0,0,0,0.4);
}






// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Data Submission Ag Grid Style

#dataSubmission .ag-theme-material {
  // background-color: #184562;
}

.ag-theme-material .ag-popup-editor,
#dataSubmission .ag-theme-material .ag-popup-editor {
  // background-color: #184562;
}

#dataSubmission .ag-theme-material .ag-cell.ag-cell-inline-editing {
    background-color: #184562;
}

.ag-theme-material .ag-cell-inline-editing,
#dataSubmission .ag-theme-material .ag-cell-inline-editing {
  // background-color: #184562;
  height: auto;
}

//
#dataSubmission .ag-theme-material .ag-side-bar {
  // background-color: #184562;
  border-left: 2px solid black;
}

#dataSubmission .ag-theme-material .ag-tool-panel-wrapper {
  border-left: 2px solid black;
}

@media screen and (min-width: 480px) {
    #dataSubmission .ag-theme-material .ag-tool-panel-wrapper {
        width: 300px;
    }
}
@media screen and (min-width: 800px) {
    #dataSubmission .ag-theme-material .ag-tool-panel-wrapper {
        width: 500px;
    }
}
@media screen and (min-width: 1200px) {
    #dataSubmission .ag-theme-material .ag-tool-panel-wrapper {
        width: 900px;
    }
}

#dataSubmission .ag-theme-material .ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}
.ag-theme-material .ag-side-bar .ag-side-buttons .ag-selected button {
  // background-color: #184562;
}

// force select to look like part of the theme
#dataSubmission .ag-theme-material .ag-tab-body .ag-filter-select {
   color: white;
   background-color: #184562;
   border: 1px solid #9dd162;
   border-radius: 3px;
   padding: 5px;
}

// make the input label more visible
#dataSubmission .ag-theme-material .ag-filter input[type="text"]::placeholder, .ag-theme-material .ag-filter input[type="date"]::placeholder {
    color: rgba(255, 255, 255, 0.38);
}

// make the input value more visible
#dataSubmission .ag-theme-material .ag-floating-filter-body input {
    padding-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}
// add a bolder box shadow to make the filter menue easier to see
#dataSubmission .ag-theme-material .ag-menu {
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.25), 0 1px 5px 0 rgba(0, 0, 0, 0.25);
}

#dataSubmission .ag-row-focus {
    // border: 2px solid #9dd162;
    background: rgba(0,0,0,0.4);
}

#dataSubmission .ag-theme-material .ag-cell {
    border-radius: 2px;
}
