.hint-content-wrapper a,
.hint-content-wrapper a:visited {
  color: #9dd162;
}
.hint-content-wrapper a:hover,
.hint-content-wrapper a:active {
  color: white;
}

.hint-content-wrapper ul {
  list-style: none; /* Remove default bullets */
  padding: 0 0 0 1.6em;
}

.hint-content-wrapper ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #9dd162; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
