body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat, sans-serif;
  /* background-color: #363636; */
}

html {
  background-image: linear-gradient(
     to bottom right,
     #1c4b6b,
     rgb(48, 120, 158),
     #265777,
     rgb(51, 119, 155)
   );

  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* setting height to 100% prevents background from extending
     to the bottom of the page when certain elements are expanded
     and cause the page height to lengthen; so we set min-height instead */
  min-height: 100%;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

::-webkit-scrollbar {
    max-width: 5px;
    max-height: 5px;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

body {
  overflow: auto;
  scrollbar-color: #619526 transparent;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.js-plotly-plot {
  margin: 0 auto;
}

.overlays canvas {
  mix-blend-mode: multiply;
}


/* webkit scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #619526;
  border-radius: 4px;
}

/* main page scrollbar should be diminished, not bright green */
body::-webkit-scrollbar-thumb {
  background-color: gray;
}

body::-webkit-scrollbar-track {
  background-color: #aaa;
}

.fake {
    color: #9dd162;
    color: rgba(97, 149, 38, .4);
}
